<script>
import APP_CONST from "@/constants/AppConst";
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  name: "Donut",
  props: ["data", "showPercentage", 'renderPrintKey', 'isMobileWidth', 'backgroundColors', 'isTooltipVisible', 'centerTextData'],
  mounted() {
    let chartData = {
      labels: [],
      datasets: [
        {
          label: "Views by Category",
          data: [],
          borderWidth: 0,
          weight: 1,
          backgroundColor: this.backgroundColors
        }
      ],
      showPercentage: this.showPercentage || false,
      isMobileWidth: this.isMobileWidth || false
    };

    let modifier = {};
    modifier = this.data;
    chartData.centerTextObject = this.centerTextData;
    chartData.datasets[0].hoverBackgroundColor = this.backgroundColors;

    for (let category in modifier) {
      chartData.labels.push(category);
      chartData.datasets[0].data.push(modifier[category]);
    }

    this.addPlugin({
      id: 'my-plugin',
      beforeDraw: function (chart) {
        var width = chart.chart.width, height = chart.chart.height, ctx = chart.chart.ctx;
        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = chart.data.isMobileWidth ? `bold ${APP_CONST.STYLE.FONT_SIZE.HEADLINE_300_LIGHT_SIZE} ${APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT}` : `bold 26px ${APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT}`;
        ctx.textBaseline = "middle";

        var centerTextName = chart.data.centerTextObject.name;
        if (chart.data.showPercentage) {
          centerTextName = chart.data.centerTextObject.name + '%';
        }

        var centerTextDataWidth = Math.round((width - ctx.measureText(centerTextName).width) / 2),
          centerTextDataHeight = height / 2.3;

        centerTextDataHeight = chart.data.isMobileWidth ? centerTextDataHeight + 8 : centerTextDataHeight;
        ctx.fillText(centerTextName, centerTextDataWidth, centerTextDataHeight);
        ctx.save();

        ctx.font = `${APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_300_LIGHT_SIZE} ${APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT}`;
        var centerTextValue = chart.data.centerTextObject.value,
          centerTextValueWidth = Math.round((width - ctx.measureText(centerTextValue).width) / 2),
          centerTextValueHeight = height / 1.7;

        ctx.fillText(centerTextValue, centerTextValueWidth, centerTextValueHeight);
        ctx.save();
      }
    })

    this.renderChart(chartData, {
      responsive: true,
      rotation: 0,
      maintainAspectRatio: false,
      cutoutPercentage: 70,
      legend: {
        display: false
      },
      tooltips: {
        enabled: this.isTooltipVisible,
        displayColors: false,
        titleFontStyle: 'bold',
        titleFontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT
      },
    });

  }
};
</script>