


























































import ScholarApplicationAnalyticsComponent from "@/components/analyticsComponent/scholarAnalyticsComponent/scholarApplicationAnalyticsComponent/ScholarApplicationAnalyticsComponent";
export default ScholarApplicationAnalyticsComponent;
