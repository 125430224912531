import { Vue, Component } from 'vue-property-decorator';
import analyticsStore from '@/store/modules/analyticsStore';
import AnalyticsProgramDropdown from '../../commonComponents/analyticsProgramDropdown/AnalyticsProgramDropdown.vue';
import DoughnutChart from "@/chartcomponents/doughnutcomponent/DoughnutComponent.vue";
import APP_UTILITIES from '@/utilities/commonFunctions';
import { IApplicationPayload } from '@/Model/model';
import APP_CONST from "@/constants/AppConst";
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import LineChart from '@/chartcomponents/linecomponent/LineChart.vue';
import { ScreenText } from '@/lang/ScreenText';
import ExportDropdown from '@/commoncomponents/exportdropdown/exportDropdown.vue';
import { applicationSubmissionByStatus, totalWeeklySubmissions } from '@/services/analytics/analyticsService';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';


@Component({
  components: {
    'analytics-program-dropdown': AnalyticsProgramDropdown,
    'DoughnutChart': DoughnutChart,
    'line-chart': LineChart,
    'multi-select-dropdown': MultiSelectDropdown,
    'export-to-csv': ExportDropdown,
    "bouncing-preloader": BouncingPreloaderComponent,
  }
})


export default class ScholarApplicationAnalyticsComponent extends Vue {

  private objScreenText: ScreenText = new ScreenText();
  public exportDropdownOptions: any = APP_CONST.SCHOLARS_CSV_DROPDOWN_LIST;
  public mobileView: boolean = false;
  public doughnutStyle: Object = {
    width: "220px",
    height: "220px",
    margin: "0 auto"
  };
  public backgroundColors: string[] = [...APP_CONST.COLORS_CHART_BACKGROUND];
  public emptyStateBackgroundColor: string[] = [APP_CONST.STYLE.COLOR.NEUTRAL_700];
  public noData: Object = {
    noData: 200
  };
  public dropdownConfig = APP_CONST.USERS_DROPDOWN_CONFIG;
  public responseReceived: boolean = false;
  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }
  public yScaleLabel: string = APP_CONST.SUBMISSIONS;
  public isNoSubmissions: boolean = false;
  public emptyStateStyle: any = {};
  public close: boolean = false;
  public disableDownloadButton: boolean = false;
  public isTabletView: boolean = false;

  public menuElement: any = 0
  public programId: number = 0;
  public isLineChartLoaderVisible: boolean = false;
  public isDonutChartLoaderVisible: boolean = false;

  get selectedProgramData() {
    return analyticsStore.slectedProgram;
  }

  get doughnutDataFormat() {
    return analyticsStore.doughNutData;
  }

  get isDataLoaded() {
    return analyticsStore.isDataLoaded;
  }

  get centerTextDataDonut() {
    return analyticsStore.centerTextData;
  }

  get isDoughNutEmptyState() {
    return analyticsStore.DoughNutEmptyState;
  }

  get applicationResponseStatus() {
    return analyticsStore.applicationResponseStatus;
  }


  get applicationListData() {
    return analyticsStore.applicationList;
  }

  get applicationsSelected() {
    return analyticsStore.selectedApplications;
  }
  get isDropdownDisable() {
    return analyticsStore.isDropdownDisable;
  }

  get applicationData() {
    this.exportDropdownOptions.options[1].disable = analyticsStore.chartData.labels.length < 2;
    return analyticsStore.chartData;
  }

  get applicationListResponseRecieved() {
    return analyticsStore.applicationListResponseRecieved;
  }

  isTablet() {
    this.isTabletView = APP_UTILITIES.tabletCheck()
  }
  beforeMount() {
    this.isLineChartLoaderVisible = true;
    this.isDonutChartLoaderVisible = true;
    this.mobileView = APP_UTILITIES.isMobile()
    analyticsStore.mutateScholarNavigationSelection('application');
    if (APP_UTILITIES.getFilteredColumns('applicationselectedprogram')) {
      let program: any = APP_UTILITIES.getFilteredColumns('applicationselectedprogram');
      let selectedProgram = JSON.parse(program);
      analyticsStore.mutateSelectedProgramName(selectedProgram)
    } else {
      analyticsStore.programNameSelection();
    }
    analyticsStore.mutateApplicationStatus({ ...analyticsStore.getApplicationResponseStatus, responseStatus: 0 });
  }

  mounted() {
    this.isTabletView = APP_UTILITIES.tabletCheck()
    window.addEventListener("resize", APP_UTILITIES.debounce(this.isTablet));
    setTimeout(() => {
      this.getApplicationSites()
    }, 200)

  }

  selectProgramData(selectedProgram: any) {
    this.isLineChartLoaderVisible = true;
    this.disableDownloadButton = true;
    analyticsStore.mutateApplicationStatus({ ...analyticsStore.getApplicationResponseStatus, responseStatus: 0 });
    APP_UTILITIES.setFilteredColumns('applicationselectedprogram', selectedProgram)
    analyticsStore.mutateDropdownState(true);
    analyticsStore.getApplicationSites(selectedProgram.programId).then(() => {
      setTimeout(() => {
        let payload: IApplicationPayload = {
          program: this.selectedProgramData.programId,
          applicationIds: this.applicationsSelected
        }
        analyticsStore.fetchApplicationData(payload);
        analyticsStore.renderChart(this.applicationsSelected).then(() => {
          this.isLineChartLoaderVisible = false;
        })
      }, 700);
    });
  }

  getApplicationSites() {
    if (this.selectedProgramData && this.selectedProgramData.programId) {
      analyticsStore.getApplicationSites(this.selectedProgramData.programId).then(() => {
        setTimeout(() => {
          let payload: IApplicationPayload = {
            program: this.selectedProgramData.programId,
            applicationIds: this.applicationsSelected
          }
          analyticsStore.fetchApplicationData(payload);
          analyticsStore.renderChart(this.applicationsSelected).then(() => {
            this.isLineChartLoaderVisible = false;
          })
        }, 700);

      })
    }
  }

  get maxYAxisData() {
    let data: any = {};
    if (this.applicationData && this.applicationData.datasets && this.applicationData.datasets.length) {
      this.applicationData.datasets.forEach((element: any) => {
        if (element.data) {
          const y = element.data.map((ele: any) => {
            return ele.y;
          })
          element.maxY = Math.max(...y)
        }
      })
      const maxYY = this.applicationData.datasets.map((element: any) => {
        return element.maxY;
      })
      let maxYValue = Math.max(...maxYY);
      let finalYValue: number = maxYValue
      if (maxYValue < 100) {
        finalYValue = Math.round(maxYValue / 10) * 10
        return finalYValue > maxYValue ? finalYValue : finalYValue + 10
      } else if (maxYValue < 1000) {
        finalYValue = Math.round(maxYValue / 50) * 50
        return finalYValue > maxYValue ? finalYValue : finalYValue + 50
      } else {
        finalYValue = Math.round(maxYValue / 500) * 500
        return finalYValue > maxYValue ? finalYValue : finalYValue + 500
      }

    } else {
      return APP_CONST.HUNDRED
    }
  }

  get lineChartStyle() {
    let allApplicationLength: any = this.applicationData && this.applicationData.labels && this.applicationData.labels.length ? this.applicationData.labels.length * (this.applicationData.labels.length * 10 > 110 ? 110 : this.applicationData.labels.length * 10) : 0;
    let styles: any = {
      width: allApplicationLength > 1280 ? `${allApplicationLength}px` : '100%',
      height: "400px",
      position: "relative"
    }
    return styles;
  }

  selectApplication(event: any) {
    analyticsStore.mutateApplicationSelected(event.dropdownList);
    if (event.dropdownList && event.dropdownList.length) {
      analyticsStore.mutateApplicationApplicationName({ programId: this.selectedProgramData && this.selectedProgramData.programId, application: event.dropdownList });
      if (this.applicationListData && this.applicationListData.length && this.applicationListResponseRecieved && this.applicationsSelected) {

      }
      this.scrollReset();
    }
  }

  callapi(arg: any) {
    let applicationIds: any = [];

    if (this.applicationsSelected.length > 0) {
      applicationIds = this.applicationsSelected.map((element: string) => {
        return element.toUpperCase();
      })
    }
    else {
      this.applicationListData.forEach((element: any) => {
        applicationIds.push(element.id.toUpperCase())
      });

    }

    if (arg.key == 'Application Submission by Status') {
      this.disableDownloadButton = true;
      let payload = {
        programId: this.selectedProgramData.programId,
        applicationIds: this.applicationsSelected
      }
      applicationSubmissionByStatus(payload).then(data => {
        if (data.status == 200) {
          this.disableDownloadButton = false
        }
      })
    }
    else if (arg.key == 'Total Weekly Submissions') {
      this.disableDownloadButton = true;
      let payload = {
        programId: this.selectedProgramData.programId,
        applicationIds: applicationIds.join(',')
      }
      totalWeeklySubmissions(payload).then(data => {
        if (data.status == 200) {
          this.disableDownloadButton = false
        }
      })
    }
  }


  scrollReset() {
    let elem: any = document.getElementById('line-chart') as HTMLElement;
    elem && elem.scrollTo(0, 0);
  }

  get emptyStateMessage() {
    let emptyStateMessage = APP_CONST.BLANK;
    this.emptyStateStyle = {};
    this.isNoSubmissions = this.applicationData && this.applicationData.datasets && Object.keys(this.applicationData.datasets).every((element: any) => this.applicationData.datasets[element].data && !this.applicationData.datasets[element].data.length);
    if (this.applicationListData == APP_CONST.BLANK && this.applicationListResponseRecieved) {
      emptyStateMessage = APP_CONST.NO_APPLICATION_DATA;
      this.emptyStateStyle = {
        height: '108px',
        width: '291px',
      };
    } else if (this.isNoSubmissions) {
      emptyStateMessage = APP_CONST.AWAITING_APPLICATION_SUBMISSIONS;
      this.emptyStateStyle = {
        height: '135px',
        width: '291px',
      };
    }
    this.disableDownloadButton = emptyStateMessage && emptyStateMessage == APP_CONST.NO_APPLICATION_DATA ? true : false;
    return emptyStateMessage
  }

  multiSelectDropdownClosed() {
    this.isLineChartLoaderVisible = true;
    analyticsStore.renderChart(this.applicationsSelected).then(() => {
      this.isLineChartLoaderVisible = false;
    });
    analyticsStore.mutateApplicationStatus({ ...analyticsStore.getApplicationResponseStatus, responseStatus: 0 });
    let payload: IApplicationPayload = {
      program: this.selectedProgramData.programId,
      applicationIds: this.applicationsSelected
    }
    analyticsStore.fetchApplicationData(payload);
  }

}

